import React, { Component } from "react";
import "./index.scss";
import { DatePicker, Cascader, Select, Button, Tooltip } from "antd";
import moment from "moment";
import Globalsearch from "../../Common/Globalsearch";
import TransactionHeadsModal from "../../Common/TransactionHeadsModal";
import { SHOPIFY_NAME } from "../../../../constants";

const { Option } = Select;
const { RangePicker } = DatePicker;
class MyWalletSummaryNav extends Component {
  state = {
    arrow: false,
    dropDownOpen: false,
    WalletSummarybtn: true,
    TransactionLogsbtn: false,
    transactionHeadModal: false,
    transactionSummary: false,
  };

  child = React.createRef();

  removeModalTick(d) {
    this.child.current.removeModalTick(d);
  }

  toggleTransactionModal() {
    this.setState({ transactionHeadModal: !this.state.transactionHeadModal });
  }

  // componentDidUpdate(pp) {
  //   if (
  //     pp.walletsummarynav.WalletSummarybtn !==
  //     this.props.walletsummarynav.WalletSummarybtn
  //   ) {
  //     this.props.removeloading();
  //   }
  // }

  render() {
    const options = [
      {
        value: "transaction_type",
        label: "Transaction Type",
        children: [
          {
            value: "credit",
            label: "Credit",
          },
          {
            value: "debit",
            label: "Debit",
          },
        ],
      },
      // {
      //   value: "transaction_status",
      //   label: "Transaction Status",
      //   children: [
      //     {
      //       value: "processed",
      //       label: "Processed",
      //     },
      //     {
      //       value: "in_processing",
      //       label: "In-Processing",
      //     },
      //     {
      //       value: "pending",
      //       label: "Pending",
      //     },
      //     {
      //       value: "declined",
      //       label: "Declined",
      //     },
      //   ],
      // },
    ];

    const gloabaloptions = [
      {
        value: "transaction_type",
        label: "Transaction Type",
        children: [
          {
            value: "credit",
            label: "Credit",
          },
          {
            value: "debit",
            label: "Debit",
          },
        ],
      },
      // {
      //   value: "transaction_status",
      //   label: "Transaction Status",
      //   children: [
      //     {
      //       value: "processed",
      //       label: "Processed",
      //     },
      //     {
      //       value: "in_processing",
      //       label: "In-Processing",
      //     },
      //     {
      //       value: "pending",
      //       label: "Pending",
      //     },
      //     {
      //       value: "declined",
      //       label: "Declined",
      //     },
      //   ],
      // },
    ];

    return (
      <div className="walletsummarynav fullorder_data">
        <div className="walletnav ">
          <div className="ordfill">
            <div className="ordfillwrap">
              <div className="left" style={{ marginLeft: "7px" }}>
                <div
                  style={{
                    color: "#000",
                    fontSize: "12px",
                    letterSpacing: "0.032em",
                    lineHeight: "24px",
                  }}
                >
                  Manage Funds
                </div>
              </div>
              <div className="right">
                <p className="show_data_from" style={{ marginLeft: "8px" }}>
                  Show Data From:{" "}
                </p>

                {this.state.WalletSummarybtn ? (
                  <RangePicker
                    disabledDate={(current) => {
                      return current > moment();
                    }}
                    style={{ marginLeft: "10px" }}
                    value={[
                      this.props.transaction_graph.startDate === ""
                        ? null
                        : moment(
                            this.props.transaction_graph.startDate,
                            "YYYY-MM-DD"
                          ),
                      this.props.transaction_graph.endDate === ""
                        ? null
                        : moment(
                            this.props.transaction_graph.endDate,
                            "YYYY-MM-DD"
                          ),
                    ]}
                    onChange={(_, dateString) =>
                      this.props.onDateChange({
                        startDate: dateString[0],
                        endDate: dateString[1],
                      })
                    }
                    ranges={{
                      Today: [moment(), moment()],
                      "This Week": [
                        moment().startOf("week"),
                        moment().endOf("week"),
                      ],
                      "Last 15 Days": [moment().subtract("days", 15), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                      "Last Month": [
                        moment()
                          .subtract(1, "month")
                          .startOf("month"),
                        moment()
                          .subtract(1, "month")
                          .endOf("month"),
                      ],
                      "Last Year": [
                        moment()
                          .subtract(1, "year")
                          .startOf("year"),
                        moment()
                          .subtract(1, "year")
                          .endOf("year"),
                      ],
                    }}
                  />
                ) : this.state.transactionSummary ? (
                  <RangePicker
                    allowClear={false}
                    disabledDate={(current) => {
                      return current > moment();
                    }}
                    style={{ marginLeft: "10px" }}
                    value={[
                      this.props.transactionSummaryFilters.startDate === ""
                        ? null
                        : moment(
                            this.props.transactionSummaryFilters.startDate,
                            "YYYY-MM-DD"
                          ),
                      this.props.filters.endDate === ""
                        ? null
                        : moment(
                            this.props.transactionSummaryFilters.endDate,
                            "YYYY-MM-DD"
                          ),
                    ]}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Week": [
                        moment().startOf("week"),
                        moment().endOf("week"),
                      ],
                      "Last 15 Days": [moment().subtract("days", 15), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                      "Last Month": [
                        moment()
                          .subtract(1, "month")
                          .startOf("month"),
                        moment()
                          .subtract(1, "month")
                          .endOf("month"),
                      ],
                      "Last Year": [
                        moment()
                          .subtract(1, "year")
                          .startOf("year"),
                        moment()
                          .subtract(1, "year")
                          .endOf("year"),
                      ],
                    }}
                    onChange={(_, dateString) =>
                      this.props.transactionSummaryFilterHandler({
                        startDate: dateString[0],
                        endDate: dateString[1],
                      })
                    }
                  />
                ) : (
                  <RangePicker
                    disabledDate={(current) => {
                      return current > moment();
                    }}
                    style={{ marginLeft: "10px" }}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Week": [
                        moment().startOf("week"),
                        moment().endOf("week"),
                      ],
                      "Last 15 Days": [moment().subtract("days", 15), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                      "Last Month": [
                        moment()
                          .subtract(1, "month")
                          .startOf("month"),
                        moment()
                          .subtract(1, "month")
                          .endOf("month"),
                      ],
                      "Last Year": [
                        moment()
                          .subtract(1, "year")
                          .startOf("year"),
                        moment()
                          .subtract(1, "year")
                          .endOf("year"),
                      ],
                    }}
                    value={[
                      this.props.filters.startDate === ""
                        ? null
                        : moment(this.props.filters.startDate, "YYYY-MM-DD"),
                      this.props.filters.endDate === ""
                        ? null
                        : moment(this.props.filters.endDate, "YYYY-MM-DD"),
                    ]}
                    onChange={(_, dateString) =>
                      this.props.rangePickerChange({
                        startDate: dateString[0],
                        endDate: dateString[1],
                      })
                    }
                  />
                )}
              </div>
            </div>
            <div className="secondwrap">
              <div className="filtertab">
                <div
                  className={
                    this.props.loading2.transactions
                      ? " tab hover-transactions-underline-animation"
                      : this.state.WalletSummarybtn
                      ? "tab  hover-transactions-underline-animation-click"
                      : "tab hover-transactions-underline-animation"
                  }
                >
                  <button
                    className={this.state.WalletSummarybtn ? "active" : ""}
                    value=""
                    onClick={() => {
                      this.setState(
                        {
                          WalletSummarybtn: true,
                          TransactionLogsbtn: false,
                          transactionSummary: false,
                        },
                        () =>
                          this.props.walletnavtoggle({
                            WalletSummarybtn: true,
                            TransactionLogsbtn: false,
                            transactionSummary: false,
                          })
                      );
                    }}
                  >
                    Wallet Summary
                  </button>
                </div>

                <div
                  className={
                    this.props.loading2.transactions
                      ? " tab hover-transactions-underline-animation "
                      : this.state.TransactionLogsbtn
                      ? "tab  hover-transactions-underline-animation-click"
                      : "tab hover-transactions-underline-animation"
                  }
                >
                  <button
                    className={this.state.TransactionLogsbtn ? "active" : ""}
                    value="delivered"
                    onClick={() => {
                      this.setState(
                        {
                          WalletSummarybtn: false,
                          TransactionLogsbtn: true,
                          transactionSummary: false,
                        },
                        () =>
                          this.props.walletnavtoggle({
                            WalletSummarybtn: false,
                            TransactionLogsbtn: true,
                            transactionSummary: false,
                          })
                      );
                    }}
                  >
                    Transaction Logs
                  </button>
                </div>

                {/* transaction summary */}
                {/* <div
                  className={
                    this.props.loading2.transactions
                      ? "non-cursor-pointer tab hover-transactions-underline-animation "
                      : this.state.transactionSummary
                      ? "tab  hover-transactions-underline-animation-click"
                      : "tab hover-transactions-underline-animation"
                  }
                >
                  <button
                    className={this.state.transactionSummary ? "active" : ""}
                    value="delivered"
                    onClick={() => {
                      this.setState(
                        {
                          WalletSummarybtn: false,
                          TransactionLogsbtn: false,
                          transactionSummary: true,
                        },
                        () =>
                          this.props.walletnavtoggle({
                            WalletSummarybtn: false,
                            TransactionLogsbtn: false,
                            transactionSummary: true,
                          })
                      );
                    }}
                  >
                    Global Transaction Summary
                  </button>
                </div> */}
              </div>

              {this.state.TransactionLogsbtn ? (
                <div className="download_csv">
                  <button
                    className="clickable"
                    style={{
                      width: "120px",
                    }}
                    onClick={() => this.props.downloadCSV()}
                  >
                    Download CSV
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {this.state.WalletSummarybtn || this.state.transactionSummary ? null : (
          <>
            <div className="bottom_data">
              <div className="custom-wraper">
                <div className="Globalsearch">
                  <Tooltip
                    title={`Search by Order ID, Txn ID, Vfsku, ${SHOPIFY_NAME} ID, VF Order ID`}
                  >
                    <Globalsearch
                      data={this.props.filters}
                      change={(value) =>
                        this.props.changeHandler({ search: value.target.value })
                      }
                      searchFunc={(value) =>
                        this.props.onChangeFilter({ search: value })
                      }
                      class={"five-lg"}
                      placeholder={`Search by Order ID, Txn ID, Vfsku, ${SHOPIFY_NAME} ID, VF Order ID`}
                    />
                  </Tooltip>
                </div>
                {/* <div
                className="drop"
                style={{
                  display: "flex",
                  float: "left",
                  alignItems: "center",
                  marginLeft: "-32px",
                }}
              >
                <Cascader
                  className="cascadermenu"
                  dropdownClassName="cascadermenu-dropdown"
                  size="large"
                  placeholder="Filter By: All"
                  options={
                    this.props.match.params.storegeo === "global"
                      ? gloabaloptions
                      : options
                  }
                  expandTrigger="hover"
                  onChange={(value) =>
                    this.props.onChangeFilter({ filterBy: value })
                  }
                />
              </div> */}

                <div
                  className="store-filters"
                  style={{
                    height: 40,
                    fontSize: 12,
                    // display: "flex",
                    alignItems: "center",
                    gap: "12px",
                    // letterSpacing: "0.032em",
                  }}
                >
                  <p className="dispute-status" style={{ color: "#6E6E6E" }}>
                    Payment Status:
                  </p>
                  <Select
                    value={this.props.filters.payment_status}
                    // onDropdownVisibleChange={() => this.btnHandler()}
                    className="filter"
                    mode="multiple"
                    placeholder="Select Payment Status"
                    onChange={(val) =>
                      this.props.onChangeFilter({ payment_status: val })
                    }
                    dropdownMatchSelectWidth={false}
                    maxTagCount={2}
                    style={{ fontWeight: 500, fontSize: 12, width: "310px" }}
                  >
                    <Option value="processed">Processed</Option>
                    <Option value="in_processing">In-Processing</Option>
                    <Option value="pending">Pending</Option>
                    <Option value="declined">Declined</Option>
                  </Select>
                </div>

                <div
                  className="store-filters"
                  style={{
                    height: 40,
                    fontSize: 12,
                    // display: "flex",
                    alignItems: "center",
                    gap: "12px",
                    // letterSpacing: "0.032em",
                  }}
                >
                  <p className="dispute-status" style={{ color: "#6E6E6E" }}>
                    Transaction Type:
                  </p>
                  <Select
                    value={this.props.filters.transaction_type}
                    // onDropdownVisibleChange={() => this.btnHandler()}
                    className="filter"
                    mode="multiple"
                    placeholder="Select Transaction Type"
                    onChange={(val) =>
                      this.props.onChangeFilter({ transaction_type: val })
                    }
                    dropdownMatchSelectWidth={false}
                    style={{ fontWeight: 500, fontSize: 12, width: "190px" }}
                  >
                    <Option value="debit">Debit</Option>

                    <Option value="credit">Credit</Option>
                  </Select>
                </div>
              </div>
            </div>

            {/* multi transaction filter */}
            <div className="transaction-head-btn-wraper">
              <Button
                onClick={() => this.toggleTransactionModal()}
                className="clickable"
              >
                Filter by Transaction Heads
              </Button>

              <TransactionHeadsModal
                ref={this.child}
                storegeo={
                  this.props.match.params.storegeo === "global"
                    ? "global"
                    : "india"
                }
                toggleModal={() => this.toggleTransactionModal()}
                visible={this.state.transactionHeadModal}
                cancelHandler={() =>
                  this.setState({ transactionHeadModal: false }, (e) =>
                    this.props.onChangeFilter({ transactionHeads: [] })
                  )
                }
                handler={(arr) =>
                  this.props.onChangeFilter({ transactionHeads: arr })
                }
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default MyWalletSummaryNav;
